*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
}

:root {
	--base-color: #fd9f01;
	--background-color: white;
}

body {
	margin: 0;
	font-family: "DM Sans", "Roboto", sans-serif;
	font-size: 1.3rem;
	background-color: var(--background-color);
}

img {
	max-width: 100%;
}

/* FallBack UI Start */

.fallBack-UI {
	display: flex;
	text-align: center;
	justify-content: center;
	min-height: 95vh;
	align-items: center;
}

.fallBack-UI .loaderWrapper {
	max-height: 8rem;
}

/* FallBack UI End */

/* For removing up and downarrow for input type number  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.mainButton {
	background-color: #fd9f01 !important;
	color: white !important;
	text-decoration: none;
	/* border: none; */
	font-size: 0.875rem;
	font-weight: 500;
	border: 1px solid transparent;
}

.mainButton:hover {
	background-color: #fd9f01 !important;
	opacity: 0.8 !important;
	text-decoration: none;
}

.mainButton:disabled {
	opacity: 0.7 !important;
	text-decoration: none;
}

.divider-db {
	border-top: solid 2px #d8d7d7;
	width: 100%;
}

.header-db {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 20px;
	min-height: 40px;
	margin: 0 auto;
	padding: 0 1rem;
}

.infinite-scroll-component {
	overflow: hidden !important;
}

.emptyPage {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 75vh;
	max-width: 25rem;
	padding: 0 2rem;
}

.remove-underline,
.remove-underline:hover {
	text-decoration: none;
}

/* For putting min height of page , helpful expecially for no data page */
.minHeightByCalculatingNavbar {
	min-height: 92vh;
}

@media only screen and (min-width: 600px) {
	.minHeightByCalculatingNavbar {
		min-height: 91vh;
	}
}

@media only screen and (min-width: 900px) {
	.minHeightByCalculatingNavbar {
		min-height: 90vh;
	}
}

@media only screen and (min-width: 1200px) {
	.minHeightByCalculatingNavbar {
		min-height: 89.5vh;
	}
}

.page-header {
	font-size: 2.125rem;
	font-weight: 400;
	letter-spacing: 0.00735em;
	text-align: center;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.changeColorToMain {
	color: var(--base-color);
}

/* For Full Screen */
/* *:fullscreen *:-ms-fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
	overflow: auto !important;
} */
